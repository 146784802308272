import { init, browserTracingIntegration } from '@sentry/react'

import { getCurrentEnvironment, isLocal } from './getClientConfig'

export function initSentry(): void {
  if (isLocal) {
    console.log('Sentry is disabled in local development.')
    return
  }

  const currentEnv = getCurrentEnvironment() ?? 'undetermined'

  init({
    dsn: 'https://f3ed821f015fadd71629e8f3b3743a68@o60155.ingest.sentry.io/4505754745962496',
    release: 'none',
    environment: currentEnv,
    integrations: [browserTracingIntegration()],
    normalizeDepth: 6,
  })
}
