import { useState, type FC, useEffect } from 'react'

import { useGetSponsors } from '../../api/hooks/sponsor'
import { useGetFeatureToggleByName } from '../../api/hooks/useGetFeatureToggleByName'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import type { BookOfBusinessFilter } from '../../context/BookOfBusinessFilterContext'
import { BookOfBusinessFilterContext } from '../../context/BookOfBusinessFilterContext'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { BenefitStatus, filterSponsorsBenefitsByBenefitStatus } from '../../utils/benefit'

import { useEmptyText } from './useEmptyText'

const sponsorStatus = 'active'

export const ActiveSponsors: FC = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const [filter, setFilter] = useState<BookOfBusinessFilter>({ sponsorStatus, ...sort })
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounced(searchQuery, 500)
  const auth = useAuth()
  const statusFilterFeatureToggle = useGetFeatureToggleByName('BEX_STATUS_FILTER')
  const isStatusFilterEnabled = statusFilterFeatureToggle.data?.enabled ?? false
  const enabled = auth.user?.permissions.isInternalUser === false && statusFilterFeatureToggle.isFetched
  const bookOfBusiness = useGetSponsors(
    {
      query: {
        // Not sending any status value allows to retrieve all sponsors.
        ...(isStatusFilterEnabled ? {} : { sponsorStatus }),
        q: debouncedSearchQuery,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )

  const emptyText = useEmptyText(
    debouncedSearchQuery,
    'You currently have no active clients. Your clients will appear here after they are onboarded with Sun Life.',
  )
  const sponsors = bookOfBusiness.data?.bookOfBusiness?.sponsors ?? []

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchQueryChange() {
      if (searchQuery.length > 0 && searchQuery === debouncedSearchQuery) onPageChange(1)
    },
    [debouncedSearchQuery, onPageChange, searchQuery],
  )

  useEffect(() => {
    setFilter((prev) => ({ ...prev, q: debouncedSearchQuery }))
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery) {
      analytics.track('book of business table:search active employers', {
        query: debouncedSearchQuery,
      })
    }
  }, [debouncedSearchQuery])

  useEffect(() => {
    if (debouncedSearchQuery.length > 0 && sponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty active employers query`,
      })
  }, [debouncedSearchQuery, sponsors.length])

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query)
  }

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download active employers',
    })
  }

  return (
    <BookOfBusinessFilterContext.Provider value={filter}>
      <QueryResultUnconditional query={statusFilterFeatureToggle}>
        {(data) => {
          const isStatusFilterEnabled = data?.enabled ?? false
          return (
            <BookOfBusinessActionBar
              tab={BookOfBusinessTabKey.allSponsors}
              downloadButtonProps={{
                disabled: sponsors.length == 0,
                text: isStatusFilterEnabled ? 'Download all clients' : 'Download active clients',
                fileType: sponsorStatus,
                onDownloadClick: () => handleDownloadClick(),
              }}
              label="Search in clients..."
              searchQuery={searchQuery}
              onSearchQueryChange={handleSearchQueryChange}
            />
          )
        }}
      </QueryResultUnconditional>
      <QueryResultUnconditional query={bookOfBusiness}>
        {(data) => {
          const sponsors = filterSponsorsBenefitsByBenefitStatus(data?.bookOfBusiness?.sponsors ?? [], [
            BenefitStatus.RISK_APPROVED,
          ])

          return (
            <BookOfBusinessTable
              type="all"
              sponsors={sponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </BookOfBusinessFilterContext.Provider>
  )
}
