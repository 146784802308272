export function getAllSponsorPoliciesTerminated90DaysAgo(sponsor: Sponsor) {
  return (
    sponsor.policies.length > 0 &&
    sponsor.policies.every((policy) => {
      const terminated = policy.policyStatus.toLowerCase() === 'terminated'
      if (!terminated) return false
      const terminatedDaysAgo = (Date.now() / 1000 - policy.renewalDate) / 60 / 60 / 24
      return terminatedDaysAgo >= 90
    })
  )
}

export function getSponsorStatusText(sponsorStatus: string): string {
  switch (sponsorStatus) {
    case 'ACTIVE':
      return 'Active'
    case 'TERMINATED':
      return 'Terminated'
    default:
      return 'Unknown'
  }
}
