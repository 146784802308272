import { AdvancedPagination, Table } from '@component-library/helios'
import { useMemo, useEffect, type ComponentProps, type ReactNode } from 'react'

import { EmptyState } from './EmptyState'
import styles from './PaginatedTable.module.css'

type InitialPagination = {
  page: number
  pageSize: number
}

const defaultInitialPagination: InitialPagination = {
  page: 1,
  pageSize: 10,
}

export type PaginatedTableProps = {
  table: ComponentProps<typeof Table>
  emptyText?: ReactNode
  initialPagination?: InitialPagination
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  onSortChange?: (field: string, direction: SortDirection) => void
  totalItems: number
}

export const PaginatedTable = ({
  table,
  emptyText,
  initialPagination,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  totalItems,
}: PaginatedTableProps) => {
  const initialPage = useMemo(() => initialPagination?.page ?? defaultInitialPagination.page, [initialPagination?.page])
  const initialItemsPerPage = useMemo(
    () => initialPagination?.pageSize ?? defaultInitialPagination.pageSize,
    [initialPagination?.pageSize],
  )

  useEffect(() => {
    onPageChange?.(initialPage)
    onRowsPerPageChange?.(initialItemsPerPage)

    // NOTE: only on inital page load, hence no dependencies / ignore lint warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageChange = (page: number) => {
    onPageChange?.(page)
  }

  const handleRowsPerPageChange = (rowsPerPage: number) => {
    onRowsPerPageChange?.(rowsPerPage)
  }

  return (
    <div>
      <Table {...table} onSort={onSortChange} className={styles.tableCustomStyle} />
      {totalItems === 0 && <EmptyState emptyText={emptyText} />}
      {totalItems > 0 && (
        <AdvancedPagination
          showLoadMore={false}
          showItemsPerPage
          itemsPerPageOptions={[10, 20, 50, 100]}
          page={initialPage}
          itemsPerPage={initialItemsPerPage}
          totalItems={totalItems}
          onChange={handlePageChange}
          // TODO change prop name - it's rows(or items) per page not items per row
          onItemsPerRowChange={handleRowsPerPageChange}
        />
      )}
    </div>
  )
}
