import { HelperText, Link, Progress } from '@component-library/helios'
import type { UseQueryResult } from '@tanstack/react-query'
import { useId, type ReactNode } from 'react'

import styles from './QueryResultUnconditional.module.css'

export type QueryResultUnconditionalProps<TData = unknown, TError = unknown> = {
  query: UseQueryResult<TData, TError>
  children: (data?: TData) => ReactNode
  loading?: ReactNode
  error?: ReactNode
}

export function QueryResultUnconditional<TData = unknown, TError = unknown>({
  query,
  children,
  loading,
  error,
}: QueryResultUnconditionalProps<TData, TError>) {
  const id = useId()
  const loadingId = 'queryResultLoading' + id
  const errorId = 'queryResultError' + id
  const dataLoading = (query.isLoading || query.isFetching) && !query.isRefetching
  const dataError = query.isError

  return (
    <>
      <div className={styles.loadingContainer} data-loading={`${dataLoading}`}>
        {loading ?? (
          <div className={styles.loading}>
            <Progress id={loadingId} label="" variant="indeterminate" />
          </div>
        )}
      </div>
      <div className={styles.errorContainer} data-error={`${dataError}`}>
        {error ?? (
          <div className={styles.error}>
            <HelperText id={errorId} error>
              <span>An error has occurred.</span>
              &nbsp;
              <Link href="#" variant="primary" size="sm" onClick={() => query.refetch()}>
                Try again
              </Link>
            </HelperText>
          </div>
        )}
      </div>
      <div className={styles.resultContainer} data-loading={`${dataLoading}`} data-error={`${dataError}`}>
        {children(query.data)}
      </div>
    </>
  )
}
